.reset__password__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  flex-direction: row;
  flex-wrap: nowrap;

  .content__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    width: 100%;
    max-width: 600px;
    border-radius: 12px;
    //padding: 24px;

    .top-section {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  img.logo {
    margin-top: 24px;
    height: 80px;
  }

  h1 {
    color: white;
    font-size: 26px;
    margin: 12px 0 24px 0;
    font-weight: 400;
    text-align: center;
  }

  .white-box {
    width: 100%;
    padding: 24px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;

    .input {
      margin-bottom: 24px;
    }

    .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error {
      font-size: 12px;
    }
  }

  p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
  }

  .confirm-button-wrapper {
    color: white;
    margin: 16px 0;

    &.disabled {
      pointer-events: none;
    }

    button {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border-width: 3px;
      position: relative;
    }
  }

  .button__icon {
    font-size: 40px;
    position: absolute;
    transition: 0.5s;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
}
