.animated-modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 64px 32px;
  overflow: scroll;
}
.animated-modal-content {
  display: flex;
  max-width: 1250px;
  border-radius: 4px;
  background-color: rgb(242, 243, 248);
  padding: 16px;
}
.animated-modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  svg {
    fill: white;
    height: 40px;
    width: 40px;
  }
}
