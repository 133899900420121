.audio-files-dropzone-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.label {
    margin-bottom: 8px;
  }
}
.audio-files-dropzone-container {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: rgba(220, 220, 220, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  position: relative;
  cursor: pointer;

  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .file-name-wrapper {
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    transform: scale(0.6);
  }
}
.content-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}
.music-wrapper {
  position: absolute;
  left: 4px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}
.delete-wrapper {
  position: absolute;
  right: 4px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}