.dropzone-container {
  display: inline-block;
}

.video-duration {
  position: absolute;
  bottom: -5px;
  right: 15px;
  background-color: white;
  border-radius: 5px;
  padding: 0 5px;
}

.dropzone-wrapper {
  width: 160px;
  height: 160px;
  border-radius: 4px;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.dropzone-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  z-index: 999;
  cursor: pointer;
  margin-left: 5px;
}

.dropzone-preview {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
