.loading-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 0.8;
  z-index: 50;
  padding: 40% 45%;
}

.table-row:hover {
  background-color: #EEEEEE;
}
