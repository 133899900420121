.amo-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
}

.amo-column {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.amo-flex1 {
  flex: 1;
}

.amo-divider {
  margin: 10px;
}

.amo-h1 {
  font-size: 28px;
  font-weight: 800;
}

.amo-h2 {
  font-size: 24px;
  font-weight: 600;
}

.amo-h3 {
  font-size: 20px;
  font-weight: 600;
}

.amo-h4 {
  font-size: 16px;
  font-weight: 500;
}

.edit-workout-sections-container {
  width: 100%;
  margin: 20px 0;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.2);
  border-radius: 4px;
}

.remove-icon-wrapper {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: white;
  }
}

.edit-workout-section-wrapper {
  width: 100%;
  border-bottom: none;
  padding: 0 16px;
  // border-bottom: 1px solid lightgrey;

  .section-header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 32px;
    }
  }
  .section-body {
    padding: 16px;
    width: 100%;
  }
}

.edit-workout-add-element {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 24px;
  cursor: pointer;

  &.small {
    height: 40px;
    font-size: 16px;
  }
}

.edit-workout-exercise {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted lightgrey;
  cursor: pointer;
}
