.exercise-selection-wrapper {
  margin: 32px 0;
  display: flex;

  div {
    height: 16px;
    width: 16px;
    margin-right: 16px;
    border-radius: 50%;
    border: solid 2px black;
    background-color: transparent;
    cursor: pointer;

    &.active {
      background-color: black;
    }
  }
}

.set-card-wrapper {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.2);
  border-radius: 10px;
  svg {
    cursor: pointer;
  }
}

.set-card-add-set {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}
